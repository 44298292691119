import React, { useEffect, useRef, useImperativeHandle, forwardRef, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import usePlotCoordinates from './hooks/usePlotCoordinates';
import useDistressMarkers from './hooks/useDistressMarkers';
import 'mapbox-gl/dist/mapbox-gl.css';
import { flyToCoordinates } from './utils/mapUtils';
import * as turf from '@turf/turf';

mapboxgl.accessToken = 'pk.eyJ1IjoiZWRkaWVzaGVlaHkiLCJhIjoiY2w1bWd3OWlyMHRuNjNkbm1ybXN4cHFwYiJ9.kcDH28yltJvjN3004Pvjtg'; // Replace with your Mapbox access token

const MapComponent = forwardRef(({ selectedRatings, distressEnabled, isAdmin, currentUser, groupedData, fetchData, setMapRefreshKey, setHighlightedRoute, setPointA, setPointB, isSelectingPoints, pointA, pointB, setIsSelectingPoints }, ref) => {
  const mapContainerRef = useRef(null);
  const mapRef = useRef(null);
  const [mapIsReady, setMapIsReady] = useState(false);
  const [distressMarkers, setDistressMarkers] = useState([]);
  const [routeIds, setRouteIds] = useState([]); // Track added routes for easy removal
  const [mapStyle, setMapStyle] = useState('mapbox://styles/mapbox/navigation-day-v1');
  const [isDarkMode, setIsDarkMode] = useState(false);

  const plotCoordinates = usePlotCoordinates(mapRef, selectedRatings, setHighlightedRoute);  // Pass setHighlightedRoute to usePlotCoordinates
  const { plotDistressMarkers, clearDistressMarkers } = useDistressMarkers(mapRef, isAdmin, setMapRefreshKey);

  const resetMapStyle = () => {
    if (mapRef.current) {
      mapRef.current.setStyle(mapStyle); // Reset the map style to clear all layers and sources
      mapRef.current.once('styledata', () => {
        setMapIsReady(true); // Ensure map is ready for further actions
      });
    }
  };

  useImperativeHandle(ref, () => ({
    plotCoordinates,
    plotPointsAndRoute,
    flyToCoordinates: (coordinates) => flyToCoordinates(coordinates, mapRef),
    refreshData: () => fetchData(currentUser?.uid),
  }));

  const getAverageRating = (coordinates) => {
    if (!coordinates || coordinates.length === 0) return 0;
    const totalRating = coordinates.reduce((acc, coord) => acc + coord.cvatRating, 0);
    return Math.round(totalRating / coordinates.length);
  };

  // Define handleClick and handleMapClick first
const handleMapClick = (e) => {
  if (isSelectingPoints) {
    const lngLat = e.lngLat;

    if (!pointA) {
      setPointA(lngLat);
    } else if (!pointB) {
      setPointB(lngLat);

      // Plot route between the two points
      plotPointsAndRoute(pointA, lngLat);

      // Reset selection mode after plotting the route
      setPointA(null);
      setPointB(null);
      setIsSelectingPoints(false);
    }
  }
};

const handleClick = (e) => {
  console.log("Map clicked");

  const features = mapRef.current.queryRenderedFeatures(e.point); // Get features at clicked point
  const zoomLevel = mapRef.current.getZoom();

  if (features.length > 0) {
    const clickedFeature = features[0];
    console.log("Clicked feature:", clickedFeature);

    const clickedVideoId = clickedFeature.properties.videoId;

    if (groupedData[clickedVideoId]) {
      const routeData = groupedData[clickedVideoId];

      if (zoomLevel >= zoomThreshold) {
        // Popup logic when zoomed in
        const point = routeData.items.find(item => item.frame === clickedFeature.properties.frame);
        if (point) {
          const imageUrl = `https://140.203.17.132:443/static/${point.frame}`;
          const popup = new mapboxgl.Popup()
            .setLngLat([point.gps_long_deg, point.gps_lat_deg])
            .setHTML(`
              <div>
                <strong>Vehicle:</strong> ${point.vehicle} <br/>
                <strong>Sample Time:</strong> ${point.sampleTime} <br/>
                <strong>Rating:</strong> ${point.cvatRating} <br/>
                <img src="${imageUrl}" alt="Frame Image" style="width: 200px; height: auto;" />
              </div>
            `)
            .addTo(mapRef.current);
        }
      } else {
        // Set highlighted route when zoomed out
        setHighlightedRoute({ video: clickedVideoId, coordinates: routeData.items, averageRating: getAverageRating(routeData.items) });
      }
    } else {
      console.warn("No route data found for the clicked feature's videoId.");
    }
  } else {
    console.warn("No features found at the clicked point.");
  }
};

const fetchRoutedPath = async (pointA, pointB) => {
  const url = `https://api.mapbox.com/directions/v5/mapbox/driving/${pointA.lng},${pointA.lat};${pointB.lng},${pointB.lat}?geometries=geojson&access_token=${mapboxgl.accessToken}`;

  try {
    const response = await fetch(url);
    const data = await response.json();
    if (data.routes && data.routes.length > 0) {
      return data.routes[0].geometry;
    }
    return null;
  } catch (error) {
    console.error("Error fetching routed path:", error);
    return null;
  }
};

const sampleEveryFiveMeters = (coordinates) => {
  const sampledCoords = [coordinates[0]]; // Start with the first coordinate
  let accumulatedDistance = 0;

  for (let i = 1; i < coordinates.length; i++) {
    const [prevLng, prevLat] = sampledCoords[sampledCoords.length - 1];
    const [lng, lat] = coordinates[i];

    const distance = turf.distance([prevLng, prevLat], [lng, lat], { units: 'meters' });
    accumulatedDistance += distance;

    if (accumulatedDistance >= 5) {
      sampledCoords.push([lng, lat]);
      accumulatedDistance = 0;
    }
  }

  return sampledCoords;
};



const plotPointsAndRoute = async (pointA, pointB) => {
  if (mapRef.current) {
    // Plot markers for points A and B
    new mapboxgl.Marker({ color: 'red' }).setLngLat([pointA.lng, pointA.lat]).addTo(mapRef.current);
    new mapboxgl.Marker({ color: 'blue' }).setLngLat([pointB.lng, pointB.lat]).addTo(mapRef.current);

    // Fetch routed path from Mapbox
    const routeGeometry = await fetchRoutedPath(pointA, pointB);
    if (!routeGeometry) {
      console.error("No route found between the points.");
      return;
    }

    // Sample the route coordinates every 5 meters
    const sampledCoordinates = sampleEveryFiveMeters(routeGeometry.coordinates);

    // Plot the GeoJSON line
    const routeGeoJSON = {
      type: "Feature",
      geometry: {
        type: "LineString",
        coordinates: sampledCoordinates,
      },
    };

    // Add the route as a line layer
    if (mapRef.current.getSource("route")) {
      mapRef.current.getSource("route").setData(routeGeoJSON);
    } else {
      mapRef.current.addSource("route", {
        type: "geojson",
        data: routeGeoJSON,
      });
      mapRef.current.addLayer({
        id: "route-line",
        type: "line",
        source: "route",
        paint: {
          "line-color": "#888",
          "line-width": 4,
        },
      });
    }
  }
};
 

  useEffect(() => {
    if (!mapRef.current && mapContainerRef.current) {
      mapRef.current = new mapboxgl.Map({
        container: mapContainerRef.current,
        style: mapStyle,
        center: [-6.2603, 53.3498],
        zoom: 7,
      });

      mapRef.current.on('load', () => {
        setMapIsReady(true); // Set map as ready when initial load is complete
      });
    }
  }, []);
  
  useEffect(() => {
    if (mapIsReady) {
      if (isSelectingPoints) {
        mapRef.current.off('click', handleClick); // Temporarily remove route click listener
        mapRef.current.on('click', handleMapClick); // Enable map click for selecting points
      } else {
        mapRef.current.off('click', handleMapClick); // Remove point selection listener
        mapRef.current.on('click', handleClick); // Re-enable route click listener
      }
    }
    return () => {
      if (mapIsReady) {
        mapRef.current.off('click', handleMapClick);
        mapRef.current.off('click', handleClick);
      }
    };
  }, [mapIsReady, isSelectingPoints, handleClick, handleMapClick]);
  
  

  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.setStyle(mapStyle);
      mapRef.current.once('style.load', () => {
        const interval = setInterval(() => {
          if (mapRef.current.isStyleLoaded()) {
            clearInterval(interval);
            setMapIsReady(true); // Confirm that the map style is fully loaded

            // Re-plot the routes after changing the map style
            if (groupedData && Object.keys(groupedData).length > 0) {
              Object.keys(groupedData).forEach((video) => {
                const coordinates = groupedData[video].items;
                if (coordinates && coordinates.length > 0) {
                  plotCoordinates(coordinates, video);
                }
              });
            }

            // Re-plot distress markers if enabled
            if (distressEnabled) {
              fetchDistressData();
            }
          }
        }, 100);
      });
    }
  }, [mapStyle]);

  useEffect(() => {
    if (mapIsReady) {
      if (!currentUser?.uid) {
        console.log("User logged out or no userID provided, resetting map style.");
        resetMapStyle(); // Reset the map style on logout to clear routes
        setDistressMarkers([]);
        return;
      }

      // Plot coordinates if there is groupedData
      if (groupedData && Object.keys(groupedData).length > 0) {
        const newRouteIds = Object.keys(groupedData).map((video) => {
          const coordinates = groupedData[video].items;
          if (coordinates && coordinates.length > 0) {
            plotCoordinates(coordinates, video);  // Call plotCoordinates to plot the route
          }
          return video;  // Return the actual video ID for route identification
        });
        setRouteIds(newRouteIds); // Store the new unique route IDs for later removal
      }
    }
  }, [mapIsReady, currentUser, groupedData, plotCoordinates]);

  // Zoom and Popup logic for routes
  const zoomThreshold = 17;


  // Attach the click event listener for the map
  useEffect(() => {
    if (mapIsReady) {
      mapRef.current.on('click', handleClick);
    }

    return () => {
      if (mapIsReady) {
        mapRef.current.off('click', handleClick);  // Remove event listener when component unmounts
      }
    };
  }, [mapIsReady, groupedData]);

  // Fetch and render distress markers
  const fetchDistressData = async () => {
    try {
      const response = await fetch('https://140.203.17.132:443/fetch-distress');
      const data = await response.json();
  
      // Log raw data for comparison
      console.log("Raw distress data:", data);
  
      // Filter data to ensure valid latitude and longitude
      const validData = data.filter((item) => {
        const [lat, lng] = item.location.split(',').map(coord => parseFloat(coord.trim()));
  
        // Check if lat and lng are valid numbers
        const isValid = !isNaN(lat) && !isNaN(lng);
        if (!isValid) {
          console.warn(`Invalid coordinates skipped: ${item.location}`);
        }
        return isValid;
      });
  
      // Log valid data to confirm correct filtering
      console.log("Valid distress data after filtering:", validData);
  
      // Plot distress markers if valid data is found
      if (validData.length > 0) {
        plotDistressMarkers(validData, setDistressMarkers);
      }
    } catch (error) {
      console.error('Error fetching distress data:', error);
    }
  };

  useEffect(() => {
    if (mapRef.current && distressEnabled) {
      fetchDistressData();
    } else if (mapRef.current && !distressEnabled) {
      clearDistressMarkers(setDistressMarkers);
    }
  }, [distressEnabled, plotDistressMarkers, clearDistressMarkers]);

  const handleMapStyleChange = (e) => {
    const newStyle = e.target.checked ? 'mapbox://styles/mapbox/navigation-night-v1' : 'mapbox://styles/mapbox/navigation-day-v1';
    setMapStyle(newStyle);
    setIsDarkMode(e.target.checked);
  };

  return (
    <div style={{ position: 'relative', width: '100%', height: '100%' }}>
      <label style={{ position: 'absolute', top: 100, right: 10, zIndex: 2, backgroundColor: isDarkMode ? 'white' : 'black', color: isDarkMode ? 'black' : 'white', padding: '5px 10px', borderRadius: '5px', cursor: 'pointer' }}>
        <input type="checkbox" onChange={handleMapStyleChange} style={{ marginRight: '5px' }} checked={isDarkMode} /> Toggle Dark Mode
      </label>
      <div ref={mapContainerRef} style={{ width: '100%', height: '100%' }} />
    </div>
  );
});

export default MapComponent;