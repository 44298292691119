import { useCallback } from 'react';
import { getColorByRating } from '../utils/mapUtils';

const usePlotCoordinates = (mapRef, selectedRatings) => {
  const plotCoordinates = useCallback((coordinates, videoId) => {
    // Check if the map is fully loaded
    if (!mapRef.current || !mapRef.current.isStyleLoaded()) {
      console.warn("Map or style is not fully loaded. Skipping plotCoordinates.");
      return;
    }

    // Clear existing layers and sources for the video
    try {
      if (mapRef.current.getSource(videoId)) {
        if (mapRef.current.getLayer(`${videoId}-points`)) {
          mapRef.current.removeLayer(`${videoId}-points`);
        }
        if (mapRef.current.getLayer(`${videoId}-line`)) {
          mapRef.current.removeLayer(`${videoId}-line`);
        }
        mapRef.current.removeSource(videoId);
      }
    } catch (error) {
      console.error("Error removing previous layers or source:", error);
    }

    // Filter coordinates based on selected ratings
    const filteredCoordinates = coordinates.filter(coord =>
      selectedRatings.length === 0 || selectedRatings.includes(coord.cvatRating)
    );

    if (!filteredCoordinates || filteredCoordinates.length === 0) return;

    // Prepare GeoJSON features for points
    const points = filteredCoordinates.map(coord => ({
      type: 'Feature',
      geometry: { type: 'Point', coordinates: [coord.lng, coord.lat] },
      properties: {
        color: getColorByRating(coord.cvatRating),
        videoId: coord.video_id, // Use the existing video_id
        frame: coord.frame,
        sampleTime: coord.sampleTime,
        vehicle: coord.vehicle,
        cvatRating: coord.cvatRating,
        gps_lat: coord.lat,
        gps_long: coord.lng,
        gps_secs: coord.gps_secs,
        gps_3d_ms: coord.gps_3d_ms
      },
    }));

    const lineSegments = [];
    const maxDistance = 0.002;

    for (let i = 1; i < filteredCoordinates.length; i++) {
      const prevCoord = filteredCoordinates[i - 1];
      const currentCoord = filteredCoordinates[i];
      const distance = Math.sqrt(
        Math.pow(currentCoord.lng - prevCoord.lng, 2) + Math.pow(currentCoord.lat - prevCoord.lat, 2)
      );

      if (distance <= maxDistance) {
        lineSegments.push({
          type: 'Feature',
          geometry: {
            type: 'LineString',
            coordinates: [
              [prevCoord.lng, prevCoord.lat],
              [currentCoord.lng, currentCoord.lat],
            ],
          },
          properties: {
            color: getColorByRating(currentCoord.cvatRating),
            videoId: currentCoord.video_id, // Use the existing video_id
            frame: currentCoord.frame,
            sampleTime: currentCoord.sampleTime,
            vehicle: currentCoord.vehicle,
            cvatRating: currentCoord.cvatRating,
            gps_lat: currentCoord.lat,
            gps_long: currentCoord.lng,
            gps_secs: currentCoord.gps_secs,
            gps_3d_ms: currentCoord.gps_3d_ms
          },
        });
      }
    }

    try {
      setTimeout(() => {
        mapRef.current.addSource(videoId, {
          type: 'geojson',
          data: { type: 'FeatureCollection', features: [...points, ...lineSegments] },
        });

        // Add line layer
        mapRef.current.addLayer({
          id: `${videoId}-line`,
          type: 'line',
          source: videoId,
          layout: { 'line-join': 'round', 'line-cap': 'round' },
          paint: {
            'line-color': ['get', 'color'],
            'line-width': 5,
            'line-opacity': 0.3,
          },
        });

        // Add points layer
        mapRef.current.addLayer({
          id: `${videoId}-points`,
          type: 'circle',
          source: videoId,
          paint: {
            'circle-radius': 6,
            'circle-color': ['get', 'color'],
            'circle-opacity': 0.3,
          },
        });
      }, 100); // Adjust delay as needed
    } catch (error) {
      console.error("Error adding new layers or source:", error);
    }
  }, [mapRef, selectedRatings]);

  return plotCoordinates;
};

export default usePlotCoordinates;
