import React from 'react';
import ReactDOM from 'react-dom';
import DistressPopup from '../Popups/DistressPopup';
import mapboxgl from 'mapbox-gl';

const DistressPopupContainer = ({ item, isAdmin, mapPopup, setMapRefreshKey, mapRef }) => {
  // Function to handle updating a distress marker
  const onConfirm = async (id, type, comments) => {
    if (!isAdmin) return;
  
    try {
      const response = await fetch('https://140.203.17.132:443/update-distress-type', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({ id, type, comments }),
      });
  
      if (!response.ok) {
        throw new Error("Failed to update distress type");
      }
  
      const data = await response.json();
      console.log("Update successful:", data);
  
      // Assuming you have access to the map instance through mapRef
      if (mapRef && mapRef.current) {
        const mapInstance = mapRef.current;
        const currentCenter = mapInstance.getCenter();
        const currentZoom = mapInstance.getZoom();
      
        // Re-create the marker as necessary
        const color = type === "Solved" ? "green" : "purple";
        const markerPosition = mapPopup._marker.getLngLat();
      
        // Remove the old marker
        mapPopup._marker.remove();
      
        // Add the new marker without altering the map's view
        const newMarker = new mapboxgl.Marker({ color })
          .setLngLat(markerPosition)
          .setPopup(mapPopup)
          .addTo(mapInstance);
      
        mapPopup._marker = newMarker;
      
        // Restore the map view to its previous state
        mapInstance.jumpTo({ center: currentCenter, zoom: currentZoom });
      } else {
        console.error("Map reference is not available.");
      }
  
    } catch (error) {
      console.error("Error updating distress report:", error);
    }
  };  
  
  // Function to handle deleting a distress marker
  const onDelete = async (id) => {
    if (!isAdmin) return; // Non-admin users shouldn't delete distress markers
    try {
      const response = await fetch('https://140.203.17.132:443/delete_distress_report', {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id: String(id) }), // Ensure id is sent as a string
      });
  
      if (!response.ok) {
        throw new Error("Failed to delete distress report");
      }
  
      const data = await response.json();
      console.log("Delete successful:", data);
  
      // Remove the marker from the map
      mapPopup._marker.remove();
    } catch (error) {
      console.error("Error deleting distress report:", error);
    }
  };  

  return mapPopup ? ReactDOM.createPortal(
    <DistressPopup
      item={item}
      isAdmin={isAdmin}
      onConfirm={onConfirm}
      onDelete={onDelete}
    />,
    mapPopup._content
  ) : null;
};

export default DistressPopupContainer;
