// src/firebaseConfig.js

import { initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword, signOut } from "firebase/auth";

// Replace these with your Firebase project configuration
const firebaseConfig = {
  apiKey: "AIzaSyC_Wyedqzxsa7DcGKmp5iWnJEQ0Nxeo_WY",
  authDomain: "paveanalytics-95c79.firebaseapp.com",
  databaseURL: "https://paveanalytics-95c79-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "paveanalytics-95c79",
  storageBucket: "paveanalytics-95c79.appspot.com",
  messagingSenderId: "774061674593",
  appId: "1:774061674593:web:d0c08fd2f14b81d90d9a20",
  measurementId: "G-CKFRHG5VH9"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { auth, signInWithEmailAndPassword, signOut };
