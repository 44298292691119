import React, { useState, useEffect } from 'react';
import './UploadPopup.css';

const UploadPopup = ({ isOpen, onClose, currentUser }) => {
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [isGoPro, setIsGoPro] = useState(false); // State for GoPro checkbox
  const [vehicle, setVehicle] = useState('BICYCLE'); // State for vehicle selection
  const [webSocket, setWebSocket] = useState(null); // Store WebSocket connection

  useEffect(() => {
    // Establish WebSocket connection
    const ws = new WebSocket('wss://140.203.17.132/ws');
    
    ws.onopen = () => {
      console.log("WebSocket connection established");
    };

    ws.onmessage = (event) => {
      console.log("Received message:", event.data);
      // Display the message in the front end (status updates from backend)
      setLoadingMessage(event.data);
    };

    ws.onerror = (error) => {
      console.log("WebSocket error:", error);
    };

    ws.onclose = () => {
      console.log("WebSocket connection closed");
    };

    setWebSocket(ws);

    return () => {
      if (ws) ws.close();
    };
  }, []); // Open WebSocket when component mounts

  const handleVideoUpload = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const userUUID = currentUser ? currentUser.uid : "User not logged in";

    const formData = new FormData();
    formData.append('file', file);
    formData.append('userUUID', userUUID); // Adding the UUID to the form data
    formData.append('isGoPro', isGoPro ? 'true' : 'false'); // Adding the isGoPro flag to form data
    formData.append('vehicle', vehicle); // Add selected vehicle type to form data

    const xhr = new XMLHttpRequest();

    xhr.upload.onprogress = (event) => {
      if (event.lengthComputable) {
        const percentComplete = Math.round((event.loaded / event.total) * 100);
        setUploadProgress(percentComplete);
        setLoadingMessage('Uploading...');
        console.log(`Upload progress: ${percentComplete}%`);

        if (percentComplete === 100) {
          setLoadingMessage("Processing video, this might take a few minutes...");
        }
      }
    };

    xhr.onloadstart = () => {
      setIsLoading(true);
      setUploadProgress(0);
      setLoadingMessage('Uploading...');
      console.log('Upload started');
    };

    xhr.onload = () => {
      if (xhr.status === 200) {
        console.log('Upload complete');
        setLoadingMessage('Upload complete, processing...');
      } else {
        alert(`Failed to upload file: ${xhr.statusText}`);
        setIsLoading(false);
        setLoadingMessage('');
      }
    };

    xhr.onloadend = () => {
      console.log('Upload ended');
      setIsLoading(false);
    };

    xhr.onerror = () => {
      alert('Upload failed, please check your network connection.');
      setIsLoading(false);
      setLoadingMessage('');
    };

    // Use the new /upload-file endpoint for uploading the file with GoPro flag
    xhr.open('POST', `https://140.203.17.132:443/upload-file`, true);
    xhr.send(formData);
  };

  if (!isOpen) return null; // Don't render if the popup is not open

  return (
    <div className="popup">
      <div className="popup-content">
        <button className="close-button" onClick={onClose}>X</button>
        <div className="upload-container">
          <label htmlFor="file-upload" className="custom-file-upload">
            Choose file
          </label>
          <input
            id="file-upload"
            type="file"
            className="fileInput"
            accept="video/*, application/zip"
            onChange={handleVideoUpload}
            disabled={isLoading} // Disable input while uploading
          />

          {/* Checkbox to select if it's a GoPro video */}
          <label className="gopro-checkbox">
            <input
              type="checkbox"
              checked={isGoPro}
              onChange={(e) => setIsGoPro(e.target.checked)} // Toggle GoPro state
            />
            Is this a GoPro video?
          </label>

          {/* Dropdown to select vehicle type */}
          <label className="vehicle-dropdown-label" htmlFor="vehicle-select">
            Select vehicle type:
          </label>
          <select
            id="vehicle-select"
            className="vehicle-dropdown"
            value={vehicle}
            onChange={(e) => setVehicle(e.target.value)}
            disabled={isLoading} // Disable dropdown while uploading
          >
            <option value="BICYCLE">Bicycle</option>
            <option value="E_BIKE">E-Bike</option>
            <option value="E_SCOOTER">E-Scooter</option>
            <option value="WHEELCHAIR">Wheelchair</option>
            <option value="STROLLER">Stroller</option>
            <option value="OTHER">Other</option>
          </select>

          {isLoading && (
            <div className="progress-bar-container">
              <div className="progress-bar" style={{ width: `${uploadProgress}%` }}>
                {uploadProgress}%
              </div>
            </div>
          )}
          {/* Display loading message (either progress or WebSocket updates) */}
          <p>{loadingMessage}</p>
        </div>
      </div>
    </div>
  );
};

export default UploadPopup;
