import React from 'react';
import './ManagePopup.css';

const ManagePopup = ({
  groupedData,
  isManagePopupOpen,
  setIsManagePopupOpen,
  handleFocusClick,
  deleteData,
  currentUser,
  isAdmin,
  setCurrentVideoId,
  setIsEditPopupOpen
}) => {
  if (!isManagePopupOpen) return null;

  return (
    <div className="popup">
      <div className="popup-content">
        <button className="close-button" onClick={() => setIsManagePopupOpen(false)}>X</button>
        <div className="manage-container">
          {Object.keys(groupedData).map(video => {
            const videoData = groupedData[video];
            console.log(videoData);
            const hasItems = videoData.items && videoData.items.length > 0;

            return (
              <div key={video} className="manage-item">
                {hasItems ? (
                  <img
                    src={`https://140.203.17.132:443/static/${videoData.items[0].frame}`}
                    alt="Thumbnail"
                  />
                ) : (
                  <div className="placeholder-thumbnail">No Thumbnail Available</div>
                )}
                <div className="manage-details">
                  <div className="details-text">
                    <p><strong>Title:</strong> {videoData.description}</p>
                    <p><strong>Length:</strong> {Math.round((videoData.video_length) / 60)} minutes</p>
                    <p><strong>Vehicle:</strong> {videoData.vehicle}</p>
                  </div>
                  <div className="details-buttons">
                    <button onClick={() => handleFocusClick(video)}>Focus</button>
                    {currentUser && isAdmin && (
                      <>
                        <button onClick={() => { setCurrentVideoId(video); setIsEditPopupOpen(true); }}>Edit</button>
                        <button onClick={() => deleteData(video)}>Delete</button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ManagePopup;
