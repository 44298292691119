import { useCallback } from 'react';
import { createRoot } from 'react-dom/client';
import mapboxgl from 'mapbox-gl';
import DistressPopupContainer from '../containers/DistressPopupContainer';

const useDistressMarkers = (mapRef, isAdmin, setMapRefreshKey) => {
  const clearDistressMarkers = useCallback((setDistressMarkers) => {
    setDistressMarkers((prevMarkers) => {
      prevMarkers.forEach(marker => marker.remove());
      return [];
    });
  }, []);

  const plotDistressMarkers = useCallback((data, setDistressMarkers) => {
    if (!mapRef.current) {
      console.error('mapRef is not initialized');
      return;
    }

    // Clear any existing distress markers
    clearDistressMarkers(setDistressMarkers);

    const markers = data.map((item) => {
      const [lat, lng] = item.location.split(',').map(coord => parseFloat(coord));
      
      // Validate coordinates
      if (isNaN(lat) || isNaN(lng)) {
        console.warn(`Invalid coordinates for distress marker: ${item.location}`);
        return null; // Skip invalid markers
      }

      const markerColor = item.type === "Solved" ? "green" : "purple";
      const marker = new mapboxgl.Marker({ color: markerColor })
        .setLngLat([lng, lat])  // Note: Mapbox uses [lng, lat]
        .addTo(mapRef.current);

      const popup = new mapboxgl.Popup({ offset: 25 }).setDOMContent(document.createElement('div'));
      marker.setPopup(popup);

      let root = null;
      popup.on('open', () => {
        if (!root) root = createRoot(popup._content);
        root.render(<DistressPopupContainer item={item} isAdmin={isAdmin} mapPopup={popup} setMapRefreshKey={setMapRefreshKey} mapRef={mapRef} />);
      });
      popup.on('close', () => {
        if (root) root.unmount();
        root = null;
      });

      return marker;
    }).filter(Boolean); // Remove any null entries from the array

    setDistressMarkers(markers);
  }, [mapRef, isAdmin, clearDistressMarkers]);

  return {
    plotDistressMarkers,
    clearDistressMarkers,
  };
};

export default useDistressMarkers;
